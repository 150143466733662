.wrapper {
  width: fit-content;
}

.rotaryEncoder {
  display: flex;
  justify-content: center;
  background-color: #444;
  border-radius: 50%;
  margin: 0 auto;
}

.dot {
  width: 5px;
  height: 5px;
  background-color: #fefefe;
  border-radius: 50%;
  margin-top: 4px;
}
